export const styleConstants = {
    generalAppStyle: "font-medium tracking-[.4px]",
    primaryColor: "rgb(38, 208, 124)",
    secondaryColor: "rgba(125, 85, 199)",
    primaryColorMiddle: "rgb(32, 158, 69)",
    primaryColorMiddleDark: "rgb(42, 130, 35)",
    primaryColorDark: "rgb(43, 99, 33)",
    primaryColorDarkTransparent: "rgb(43, 99, 33, 0.9)",
    paragraphGray: "#67666e",
    boxBackgroundGray: "#18161C",
    boxGridLayoutContainer: "flex w-full mdlg:w-3/4 lgxl:w-full justify-between gap-y-8 flex-col lgxl:flex-row",
    boxGridLayout: "flex flex-col basis-[47%]",
    ccriBoxBackgroundColor: "bg-zinc-800",
    boxStyle: "box-gradient-border px-4 py-4",
    transitionStandard: "transition-all duration-500 ease-in-out",
    transitionSlow: "transition-all duration-1000",
    transitionFast: "transition-all duration-300",
    maxWidthTextPage: "max-w-4xl",
    mobileClasses: "visible sm:hidden",
    desktopClasses: "hidden sm:visible sm:block",
    mobileMdClasses: "visible md:hidden",
    desktopMdClasses: "hidden md:visible md:block",
    roundedBorder: "border rounded-xl shadow shadow-gray-900",
}