import { Paragraph, TParagraphLinkPropsPartial, TParagraphStyleProps } from "../Paragraph"

type IEnumerationProps = TParagraphStyleProps & {
    listContent: (string | TParagraphLinkPropsPartial)[],
    isListDisc?: boolean,
}


export const Enumeration: React.FC<IEnumerationProps> = (props) => {


    const listClasses = `${props.isListDisc ? "list-disc" : "list-decimal"} px-8 md:px-16 py-2`
    const listItemClasses = "py-[5px]"


    const listParagraphs = props.listContent.map((elem, index) => {
        if (typeof elem === 'string') {
            return (<Paragraph
                {...props}
                content={elem}
            />)
        } else if (typeof elem !== 'string' &&
            "text" in elem &&
            "linkTexts" in elem &&
            "links" in elem) {
            const content: TParagraphLinkPropsPartial = {
                links: elem.links,
                linkTexts: elem.linkTexts,
                text: elem.text
            }
            return (<Paragraph
                {...props}
                content={content}
            />)
        }
    })


    return (
        <ol className={`${listClasses}`}>
            {listParagraphs.map((item, index) => (
                <li className={listItemClasses} key={index}>
                    {item}
                </li>
            ))}
        </ol>
    );

}