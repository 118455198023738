import { Action, ActionType } from "../actions/sessionActions";


export type TSessionId = string | undefined

interface ISessionReducerState {
    isBackendOffline: boolean
}
const initialState: ISessionReducerState = {
    isBackendOffline: false
};


export const sessionReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case ActionType.SET_BACKEND_OFFLINE: {
            return {
                ...state,
                isBackendOffline: true,
            }
        }

        default:
            return state;
    }
};
