import React, { useCallback, useEffect, useState } from 'react';
import { TBackendHistoricNetwork, TBackendHistoricNetworkElectricity, TBackendHistoricNetworkEmissions } from '../../types/backend-data';
import { LineChart } from '../../components/LineChart';
import { TLineChartData, TSingleLineChartData, TLineChartMetric } from '../../types/component-types';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Heading } from '../../components/Heading';
import { Paragraph } from '../../components/Paragraph';
import { styleConstants } from '../../style-constants';

interface IHistoricSectionProps {
    historicDataNetwork: TBackendHistoricNetwork
}

export const HistoricSection: React.FC<IHistoricSectionProps> = (props) => {


    const [chartData, setChartData] = useState<TLineChartData | undefined>(undefined);
    const [isL2, setIsL2] = useState<boolean | undefined>(undefined);


    useEffect(() => {
        setIsL2(props.historicDataNetwork.electricity.data[0].l2_share !== null && props.historicDataNetwork.emissions.data[0].l2_share !== null)
    }, []);


    const filterChartData = useCallback((chartType: TLineChartMetric): TSingleLineChartData => {
        const yProp = chartType === "electricity" ? "consumption" : chartType

        const singleLineChartData: TSingleLineChartData = {
            dates: [],
            y1AnnualizedValue: [],
            y2L1Share: [],
            l2Share: [],
            dailyValue: [],
        };

        props.historicDataNetwork[chartType].data.forEach((elem) => {
            const data = elem as TBackendHistoricNetworkElectricity & TBackendHistoricNetworkEmissions;
            singleLineChartData.dates.push(data.date);
            singleLineChartData.y1AnnualizedValue.push(data[`${yProp}_365d`]);
            singleLineChartData.y2L1Share.push(data[`l2_${yProp}_365d`]);
            singleLineChartData.l2Share.push(data.l2_share);
            singleLineChartData.dailyValue.push(data[`${yProp}_24h`]);
        });

        return singleLineChartData
    }, [props.historicDataNetwork]);


    const createChartsWithContainer = () => {
        const charts: TLineChartMetric[] = ["electricity", "emissions"]
        return charts.map((chartType: TLineChartMetric, index: number) => {
            return (
                <div
                    className={`${styleConstants.boxGridLayout} ${styleConstants.boxStyle}`}
                    key={`historic-chart-${chartType}`}
                >
                    <LineChart
                        chartMetric={chartType}
                        data={chartData![chartType]}
                    />
                </div>
            )
        }
        )
    }


    const renderSharedLegend = () => {

        // do not show a legend if not a l2 chain as legend not needed
        if (!isL2) {
            return <></>
        }

        const createLegendItem = (backgroundRgb: string, label: string) => {

            return (
                <div className='flex items-center flex-row space-x-2'>
                    <div
                        className='w-[20px] h-[10px] sm:w-[30px] sm:h-[15px] rounded'
                        style={{ background: backgroundRgb }}
                    />
                    <Paragraph
                        content={label}
                        isWhiteText
                        isThinText
                    />
                </div>
            )
        }

        return (
            <div className="flex flex-col md:flex-row w-full items-center justify-center space-x-0 space-y-2 md:space-x-8 md:space-y-0">
                {createLegendItem(styleConstants.secondaryColor, "Total share (L1 and L2)")}
                {createLegendItem(styleConstants.primaryColorDark, "Polygon share (L2 only)")}
            </div>
        )
    }


    useEffect(() => {
        const chartData: TLineChartData = {
            electricity: filterChartData('electricity'),
            emissions: filterChartData('emissions'),
        }
        setChartData(chartData)
    }, [filterChartData])


    const renderComponent = () => {

        return (
            <div
                className='flex flex-col items-center w-full'
            >
                <Heading
                    text={'Historical Data'}
                    isSubheading
                    hasMediumSmallBottomPadding
                    isMdTextCenter
                />
                {undefined === chartData ?
                    <LoadingSpinner />
                    :
                    // <div className='flex flex-col w-full space-y-4'>
                    <div className={styleConstants.boxGridLayoutContainer}>
                        {/* <div className='flex flex-row flex-wrap justify-center w-full'> */}
                        {/* <div  className={styleConstants.boxGridLayout}> */}
                        {createChartsWithContainer()}
                        {/* </div> */}
                        {renderSharedLegend()}
                    </div>
                }
            </div>
        )
    }

    return (
        renderComponent()
    )
};

