
import WorldBallDesktop from "../../assets/images/solana-green-ball-desktop.png"
import WorldBallMobile from "../../assets/images/solana-green-ball-mobile.png"
import { styleConstants } from '../../style-constants';



export const WorldBall: React.FC = () => {

    return (
        <>
            <div className={`${styleConstants.desktopMdClasses} absolute top-0 right-0 z-[-1] h-[300px] w-auto`}>
                <img
                    src={WorldBallDesktop}
                    alt="world-ball"
                    className="h-full w-auto"
                />
            </div>
            <div className={`${styleConstants.mobileMdClasses} flex justify-center z-[-1] h-auto max-w-[400px] px-4 -mb-64`}>
                <img
                    src={WorldBallMobile}
                    alt="world-ball"
                    className="h-full w-auto rotate-180"
                />
            </div>
        </>
    )
}