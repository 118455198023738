import axios from "axios";
import { TBackendHistoric, TBackendKpi, TBackendMapData, TBackendMica, TBackendTickers } from "../types/backend-data";
import { customErrors } from "./backend-erros";


const backendUrlHost = process.env.REACT_APP_APPLICATION_BACKEND_HOST
const backendUrlSuffix = "localhost" === backendUrlHost ? "" : "/api"
const backendUrlPort = "localhost" === backendUrlHost ? `:${process.env.REACT_APP_APPLICATION_BACKEND_PORT}` : ""
const backendURL = `${process.env.REACT_APP_APPLICATION_BACKEND_PROTOCOL}://${backendUrlHost}${backendUrlPort}${backendUrlSuffix}`
const backendAuth = `${process.env.REACT_APP_APPLICATION_BACKEND_AUTH}`


const createAxiosInstance = (contentType: "application/x-www-form-urlencoded" | "application/json") => {
    return axios.create({
        baseURL: backendURL,
        headers: {
            'Content-Type': contentType,
            'Accept': 'application/json',
            'X-Frontend-Token': backendAuth,
        },
    });
};

const jsonBackendApiAxiosInstance = createAxiosInstance("application/json")
// const urlEncodedApiAxiosInstance = createAxiosInstance("application/x-www-form-urlencoded")


export const backendGetTickers = async () => {
    try {
        const response = await jsonBackendApiAxiosInstance.get('/tickers');
        return (response.data["result"] as TBackendTickers)
    } catch (error: any) {
        console.error(error); // Handle the error 
        throw error
    }
}


export const backendGetMapData = async () => {
    try {
        const response = await jsonBackendApiAxiosInstance.get('/map-data');
        return (response.data["result"] as TBackendMapData)
    } catch (error: any) {
        console.error(error); // Handle the error
        throw error
    }
}


export const backendGetKpiData = async () => {
    try {
        const response = await jsonBackendApiAxiosInstance.get('/kpis');
        return (response.data["result"] as TBackendKpi)
    } catch (error: any) {
        console.error(error); // Handle the error
        throw error
    }
}


export const backendGetHistoricData = async () => {
    try {
        const response = await jsonBackendApiAxiosInstance.get('/historical-data');
        return (response.data["result"] as TBackendHistoric)
    } catch (error: any) {
        console.error(error); // Handle the error
        throw error
    }
}


export const backendGetMicaData = async () => {
    try {
        const response = await jsonBackendApiAxiosInstance.get('/mica-data');
        return (response.data["result"] as TBackendMica)
    } catch (error: any) {
        console.error(error); // Handle the error
        throw error
    }
}

