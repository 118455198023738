import { configureStore } from '@reduxjs/toolkit'
import { sessionReducer } from './reducers/sessionReducer';
import { dashboardReducer } from './reducers/dashboardReducer';



export const store = configureStore({
  reducer: {
    session: sessionReducer,
    dashboard: dashboardReducer,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


