import { Link, useNavigate, useLocation } from "react-router-dom";
import { TLinks } from "../../types/component-types";
import { useEffect, useState } from "react";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid'
import { Transition } from "@headlessui/react";
import { styleConstants } from "../../style-constants";
import SolanaLogo from "../../assets/logos/solana-logo.png"


interface INavbarProps {
  links: TLinks,
  setNavbarVisible: (newNavbarVisible: boolean) => void,
}


export const Navbar: React.FC<INavbarProps> = (props) => {

  const navigate = useNavigate();
  const location = useLocation();


  const [prevScrollPosition, setPrevScrollPosition] = useState<number>(0);
  const [currentActiveLink, setCurrentActiveLink] = useState<string>(location.pathname)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isMobileView, setIsMobileView] = useState<boolean | undefined>(true);


  useEffect(() => {
    setCurrentActiveLink(location.pathname)
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 200; // Define the scroll distance threshold here
      const currentScrollPosition = window.scrollY;
      const isScrolledDown = prevScrollPosition < currentScrollPosition;

      if (currentScrollPosition > scrollThreshold) {
        props.setNavbarVisible(!isScrolledDown);
        closeMobileMenu()
      }
      setPrevScrollPosition(currentScrollPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [props, prevScrollPosition]);

  useEffect(() => {
    const handleResize = () => {
      const mobileMenuThreshold = 1024; //lg in tailwind
      // Determine whether to show or hide the mobile menu based on window width
      const shouldShowMobileMenu = window.innerWidth <= mobileMenuThreshold; // Adjust the breakpoint as needed
      setIsMobileView(shouldShowMobileMenu);
    };

    // Attach the resize event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Call the handleResize function initially
    handleResize();

    // Remove the resize event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleLogoClick = () => {
    navigate(props.links["home"].link)
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const createLinks = () => {
    return Object.entries(props.links).filter(link => link[1].text !== undefined).map((link) => {
      const linkKey = link[0]
      const linkValue = link[1]
      const isLinkActive = currentActiveLink.slice(1) === linkValue.link
      return (
        <li
          className={`lg:w-auto w-[200px] px-2 py-1 hover:rounded-lg hover:bg-[rgb(20,18,23)] hover:hover:text-primaryColor ${styleConstants.transitionStandard} ${isLinkActive ? "underline decoration-primaryColor underline-offset-8 decoration-2" : ""} my-2 lg:my-0`}
          key={`navbar-link.${linkKey}`}>
          <Link
            onClick={closeMobileMenu}
            to={linkValue.link}>
            {linkValue.text}
          </Link>
        </li>
      )
    })
  }

  return (
    <nav className={`w-full bg-[#0a090d] bg-opacity-80 py-4 px-2 sm:px-4`}>
      <div className="flex items-center container mx-auto">
        <div className="flex justify-left lg:justify-center flex-shrink-0 w-1/2 lg:w-1/3 pl-4 lg:pl-0">
          <img
            src={SolanaLogo}
            alt="Solana Logo"
            className="h-6 w-auto cursor-pointer z-50"
            onClick={handleLogoClick} />
        </div>
        {
          isMobileView ?
            <div className="flex justify-end w-1/2 pr-4">
              {isMobileMenuOpen ?
                <XMarkIcon
                  className={`h-6 cursor-pointer ${styleConstants.transitionStandard} text-white hover:text-primaryColor`}
                  onClick={toggleMobileMenu}
                />
                :
                <Bars3Icon
                  className={`h-6 cursor-pointer ${styleConstants.transitionStandard} text-white hover:text-primaryColor`}
                  onClick={toggleMobileMenu}
                />
              }

            </div>
            :
            <ul className="flex flex-grow justify-center space-x-10 pr-20">
              {createLinks()}
            </ul>
        }
      </div>
      {/* Mobile menu */}
      <Transition
        show={isMobileMenuOpen}
        enter={`${styleConstants.transitionStandard} transform`}
        enterFrom="opacity-0 scale-5"
        enterTo="opacity-100 scale-100"
        leave={`${styleConstants.transitionStandard} transform`}
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-5"
        className={"relative"}
      >
        <ul
          className={`lg:hidden bg-[#0a090d] w-full absolute top-4 left-1/2 transform -translate-x-1/2 px-4 text-center flex flex-col items-center justify-center opacity-80`} 
        >
          {createLinks()}
        </ul>
      </Transition>

    </nav>
  );
}