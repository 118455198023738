import React from 'react';
import { Paragraph } from '../Paragraph';
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { XCircleIcon } from '@heroicons/react/20/solid'


interface IOutdatedAlertProps {
    timestamp: string,
    hasTopMargin?: boolean,
}

export const OutdatedAlert: React.FC<IOutdatedAlertProps> = (props) => {

    const isValueUpToDate = (parsedDataTimestamp: Date): boolean => {

        const timeThreshold = 48 * 60 * 60 * 1000; // 48 hours in milliseconds
        const currentTime = new Date();

        const timeDifference = currentTime.getTime() - parsedDataTimestamp.getTime();

        return timeDifference < timeThreshold;
    }

    const renderComponent = () => {

        const parsedInputDate = new Date(props.timestamp);
        const year = parsedInputDate.getFullYear();
        const month = String(parsedInputDate.getMonth() + 1).padStart(2, "0");
        const day = String(parsedInputDate.getDate()).padStart(2, "0");
        const dayFormatted = `${year}-${month}-${day}`;

        const iconClasses = "text-paragraphGray w-3"

        const updatedText = (
            <Paragraph
                isCaption
                content={`Values as of ${dayFormatted}`} />
        )

        return (
            <div className={`flex gap-x-1 items-center ${props.hasTopMargin ? "mt-4" : ""}`}>
                {isValueUpToDate(parsedInputDate) ?
                    <>
                        <CheckCircleIcon className={iconClasses} />
                        {updatedText}
                    </>
                    :
                    <>
                        <XCircleIcon className={iconClasses} />
                        {updatedText}
                    </>}
            </div>
        )
    }

    return (
        renderComponent()
    )
};

