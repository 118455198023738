import { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';

import { MainDashboard } from "./pages/MainDashboard"
import { RedirectComponent } from './components/RedirectComponent';
// import { Infrastructure } from './pages/Infrastructure';
import { Methodology } from './pages/Methodology';
import { Navbar } from './sections/Navbar';
import { TLinks } from './types/component-types';
import { Footer } from './sections/Footer';
import { styleConstants } from './style-constants';
import { Mica } from './pages/Mica';
import {WorldBall} from './components/WoldBall'



export const routerLinks: TLinks = {
  "home": { link: "/", text: undefined },
  "dashboard": { link: "dashboard", text: "Dashboard" },
  "mica": { link: "mica-compliance", text: "MiCA Indicators" },
  "methodology": { link: "methodology", text: "Methodology" },
  "not_found": { link: "https://solana.com/asef", text: undefined },
}

function App() {

  const [navbarVisible, setNavbarVisible] = useState<boolean>(true);


  const renderComponent = () => {

    return (
      <BrowserRouter>
        <div
          className={styleConstants.generalAppStyle}
        >
          <div className="relative min-h-screen flex items-center flex-col bg-transparent">
            <div className={`flex-initial w-full sticky top-0 z-10 ${navbarVisible ? "translate-y-0" : "-translate-y-full"} ${styleConstants.transitionStandard}`}>
              <Navbar links={routerLinks} setNavbarVisible={setNavbarVisible} />
            </div>
            <WorldBall/>
            <div
              className="px-4 sm:px-24 pt-16 pb-32 w-full max-w-screen-2xl"
            >
              <Routes>
                <Route path={routerLinks.home.link} element={<MainDashboard />}></Route>
                <Route path={routerLinks.dashboard.link} element={<MainDashboard />}></Route>
                <Route path={routerLinks.mica.link} element={<Mica />}></Route>
                <Route path={routerLinks.methodology.link} element={<Methodology />}></Route>
                <Route path="*" element={<RedirectComponent to={routerLinks.not_found.link} external />}></Route>
              </Routes>
            </div>
            <div className="absolute w-full bottom-0 z-50">
              <Footer navbarLinks={routerLinks} />
            </div>
          </div>
        </div>
      </BrowserRouter >
    );
  }

  return renderComponent()
}

export default App;
