import { Paragraph } from "../Paragraph";
import { CcriLogo } from '../../components/CcriLogo';



interface IPoweredByCcriProps {
    positioningClasses?: string
    isLargeVariant?: boolean

}

export const PoweredByCcri: React.FC<IPoweredByCcriProps> = (props) => {


    return (
        <div className={`flex ${props.isLargeVariant ? "gap-x-4" : "gap-x-2"} ${props.positioningClasses ? props.positioningClasses : ""}`}>

            <Paragraph
                content='powered by'
                isWhiteText
                customStyle="flex items-center"
            />
            <div>
                <CcriLogo
                    height={props.isLargeVariant ? "h-8 sm:h-10" : "h-6"}
                />
            </div>
        </div>)

};