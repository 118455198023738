import { styleConstants } from "../../style-constants"

export type TParagraphLinkPropsPartial = {
    text: string[],
    links: string[],
    linkTexts: string[],
    linkTargets?: (string | undefined)[],
}

export type TParagraphStyleProps = {
    isThinText?: boolean,
    isWhiteText?: boolean,
    customStyle?: string,
    isCaption?: boolean,
    isCenter?: boolean,
    isLinkNotBold?: boolean,
    isLinkNotUnderline?: boolean,
}

export type TParagraphContentType = string | TParagraphLinkPropsPartial

export type TParagraphProps = TParagraphStyleProps & {
    content: TParagraphContentType
}


export const Paragraph: React.FC<TParagraphProps> = (props) => {

    const regularClasses = `whitespace-pre-wrap	${props.isWhiteText ? "text-white" : "text-paragraphGray"} ${props.isThinText ? "font-light" : "font-normal"} ${props.isCenter ? "text-center" : "text-left"}`
    const captionClasses = props.isCaption ? `text-xs  font-normal` : ""
    const customClasses = props.customStyle ? props.customStyle : ""
    const linkClasses = `${props.isLinkNotBold ? "" : "!font-semibold"} ${props.isLinkNotUnderline ? "" : "!underline !underline-offset-4"} !text-primaryColor hover:!text-secondaryColor ${styleConstants.transitionStandard}`

    if (props.content && !Array.isArray(props.content) && typeof props.content == 'string') {
        return (<div className={`${regularClasses} ${captionClasses} ${customClasses}`}>
            {props.content}
        </div>)
    } else if (typeof props.content !== 'string' &&
        "linkTexts" in props.content && props.content.linkTexts &&
        "links" in props.content && props.content.links &&
        Array.isArray(props.content.text)) {
        const text = (props.content as TParagraphLinkPropsPartial).text
        const linkTexts = (props.content as TParagraphLinkPropsPartial).linkTexts
        const links = (props.content as TParagraphLinkPropsPartial).links
        const fullLinks = links.map((link, index) => {
            const linkTarget = (typeof props.content !== 'string' && "linkTexts" in props.content && props.content.linkTargets && props.content.linkTargets[index]) ? props.content.linkTargets[index] : "_blank"
            return (
                <a
                    href={link}
                    target={linkTarget}
                    rel="noreferrer"
                    className={`${regularClasses} ${captionClasses} ${customClasses} ${linkClasses}`}
                >
                    {linkTexts[index]}
                </a>
            )
        });

        let content = (
            <p
                className={`${regularClasses} ${captionClasses} ${customClasses}`}

            >
                {text.map((fragment, index) => (
                    <span
                        key={index}
                    >
                        {fragment}{fullLinks[index]}
                    </span>
                ))}
            </p>
        );
        return content
    } else {
        return <></>
    }

}