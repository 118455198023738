import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { TBarChartData } from '../../types/component-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from "chartjs-plugin-annotation";
import 'chartjs-adapter-moment';


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Filler,
} from "chart.js";
import { styleConstants } from '../../style-constants';


ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    Title,
    Tooltip,
    Filler,
    ChartDataLabels,
    annotationPlugin,
);


interface IBarChartProps {
    data: TBarChartData,
    solana_tx_elec_wh: number
}

export const BarChart: React.FC<IBarChartProps> = (props) => {

    const [chartFontSize, setChartFontSize] = useState<number>(10);
    const [key, setKey] = useState<number>(0); // State to control the key


    useEffect(() => {
        const handleResize = () => {
            setChartFontSize(window.innerWidth < 640 ? 11 : 13) //change the font size 
            setKey(prevKey => prevKey + 1);
        };
        handleResize()
        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup the event listener when the component unmounts
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const formatChartValue = (value: number, customUnit: string|undefined = undefined): string => {
        let rounded_number = undefined
        // special case: If the number is 0.0000001 (23 ms of led light bulb), format it to exactly 7 decimal places
        if (value < 0.0001) {
            rounded_number = value.toFixed(7); // Limit to 4 decimal places and remove trailing zeros
        }
        // If the number is less than 1, format it to at most 4 decimal places
        else if (value < 1) {
            rounded_number = parseFloat(value.toFixed(4)); // Limit to 4 decimal places and remove trailing zeros
        } else {
            // If the number is >= 1, return as an integer if possible, else limit to 4 decimal places
            rounded_number = value % 1 === 0 ? value.toLocaleString() : parseFloat(value.toFixed(4));
        }
        // const number_formatted = parseFloat(value)
        return `${rounded_number} ${customUnit ? customUnit : "kWh"}`
    }


    const truncateLink = (link_text: string): string => {
        const maxLength = 45
        if (link_text.length > maxLength) {
            return link_text.slice(0, maxLength) + '...';
        }
        return link_text;
    }

    const openChartSourceLink = (link: string) => {
        window.open(link, '_blank');
    }

    const formatSolanaTxAnnotation = () => {
        const solanaTxWh = props.solana_tx_elec_wh * 1000
        const solanaTxWhFormatted = formatChartValue(solanaTxWh, "Wh")
        return ["1 Solana TX", `${solanaTxWhFormatted}`]
    }


    const chartData = {
        labels: props.data.labels,
        datasets: [
            {
                label: "metric",
                data: props.data.values,
                maxBarThickness: 20
            }
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y' as const,
        elements: {
            bar: {
                borderWidth: 0,
                backgroundColor: styleConstants.primaryColor,
                hoverBackgroundColor: styleConstants.secondaryColor,
            },
        },
        layout: {
            padding: {
                right: 70,
                top: 50,
            }
        },
        scales: {
            x: {
                min: 0.0000001,
                display: true,
                type: 'logarithmic' as any,
                base: 2,
                beginAtZero: true,
                grid: {
                    display: false
                },
                ticks: {
                    display: false,
                },
                title: {
                    display: true,
                    text: `electricity consumption [kWh, log-scale]`,
                    color: "white",
                    font: {
                        size: chartFontSize
                    }
                },
            },
            y: {
                display: true,
                grid: {
                    display: false
                },
                ticks: {
                    display: true,
                    color: "white",
                    font: {
                        size: chartFontSize
                    },
                },
            }
        },

        plugins: {
            tooltip: {
                intersect: true,
                position: "average",
                displayColors: false,
                cornerRadius: 10,
                titleFont: {
                    size: chartFontSize
                },
                bodyFont: {
                    size: chartFontSize
                },
                callbacks: {
                    //first two values come from the dataset
                    label: (context: any) => {
                        return formatChartValue(context.dataset.data[context.dataIndex]);
                    },
                    //third source is appended manually
                    afterBody: (tooltipItem: any) => {
                        const dataIndex = tooltipItem[0].dataIndex
                        const sourceName = props.data.sources[dataIndex]
                        const link = truncateLink(props.data.links[dataIndex]);
                        return `Source: ${sourceName}\n${link}`
                    }
                }
            },
            annotation: {
                clip: false,
                common: {
                  drawTime: 'afterDraw'
                },
                annotations: {
                    line1: {
                        display: true,
                        drawtime: "beforeDatasetsDraw",
                        type: "line",
                        scaleID: "x",
                        value: props.solana_tx_elec_wh,
                        borderWidth: 2,
                        borderColor: styleConstants.secondaryColor,

                        label: {
                            drawTime: 'afterDraw',
                            display: true,
                            position: "start",
                            opacity: 1,
                            clamp: false, // Ensures the label stays within the chart area
                            content: formatSolanaTxAnnotation(),
                            backgroundColor: 'rgba(245,245,245)',
                            color: "rgba(0,0,0)",
                            z: 100,
                            yAdjust: -55,
                            yScaleID: 'y'

                        },
                    },
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: "end",
                color: 'white', 
                font: {
                    weight: 'bold', 
                    size: chartFontSize,
                },
                formatter: (value: number) => formatChartValue(value),
                clamp: true
            },
        },
        interaction: {
            mode: "point",
        },
        hover: {
            mode: 'point',
            intersect: false,

        },
        onClick: (_event: any, element: any) => {
            if(element.length > 0) {
                const elementIndex = element[0].datasetIndex
                openChartSourceLink(props.data.links[elementIndex])
            }
        },
        onHover: (event: any, element: any) => {
            // Access the canvas element to modify the cursor style
            const canvas = event.native.target as HTMLCanvasElement;
            if (canvas) {
                canvas.style.cursor = element.length > 0 ? 'pointer' : 'default';
            }
        }

    } as const


    return (
        <Bar
            data={chartData}
            options={chartOptions}
            height={360}
            key={key} // Use the key to trigger remount
        />
    )
};

