import React, { useState } from 'react';
import { getIconElement } from '../../icons';
import { Transition } from '@headlessui/react';
import { styleConstants } from '../../style-constants';
import { Paragraph } from '../Paragraph';

interface IInfoTooltipProps {
    text: string;
    type: "info" | "warning";
    customTooltipStyle?: string;
    wideTooltip?: boolean;
    customIconHeight?: string;
    customBackgroundClasses?: string;
}

export const InfoTooltip: React.FC<IInfoTooltipProps> = (props) => {
    const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

    const showTooltip = () => {
        setTooltipVisible(true);
    };

    const hideTooltip = () => {
        setTooltipVisible(false);
    };

    return (
        <div className="relative flex justify-center items-center">
            <div
                className={`${props.customIconHeight ? props.customIconHeight : "h-[15px]"} flex items-center justify-center rounded-full cursor-pointer`}
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
            >
                {getIconElement(props.type, "text-paragraphGray", 3, true)}
            </div>
            <Transition
                as={React.Fragment}
                appear={true}
                show={isTooltipVisible}
                enter={`${styleConstants.transitionStandard}`}
                enterFrom="scale-0"
                enterTo="scale-100"
                leave={`${styleConstants.transitionStandard}`}
                leaveFrom=" scale-100"
                leaveTo="scale-0"
            >

                <div className={`z-50 block fixed left-1/2 xs:left-auto transform -translate-x-1/2 xs:translate-x-0 -translate-y-[60px] xs:translate-y-0 xs:absolute xs:top-[30px] visible whitespace-pre-wrap rounded-lg shadow-2xl w-max max-w-[150px] ${props.wideTooltip ? "xs:max-w-[250px] sm:max-w-[400px]" : "xs:max-w-[250px]"} ${props.customBackgroundClasses ? props.customBackgroundClasses : "bg-gray-500 bg-opacity-85"} py-2 px-4 ${props.customTooltipStyle ? props.customTooltipStyle : ""}`}>
                    <Paragraph
                        content={props.text}
                        isCaption
                        isWhiteText
                        isCenter
                    />
                </div>
            </Transition>

        </div>
    );
};