import { CcriLogo } from "../../components/CcriLogo"
import { Enumeration } from "../../components/Enumeration"
import { Heading } from "../../components/Heading"
import { Paragraph } from "../../components/Paragraph"
import { styleConstants } from "../../style-constants"
import { Button } from "../../components/Button"
import { ScrollFadingSectionWrapper } from "../../sections/ScrollFadingSectionWrapper"
import { PageTransitionWrapper } from "../../components/PageTransitionWrapper"
import { HeroSection } from "../../sections/HeroSection"


interface IMethodologyProps {

}


export const Methodology: React.FC<IMethodologyProps> = (props) => {


    const renderButtonBox = (text: string, buttonText: string, buttonLink: string) => {

        const onDownloadReportButtonClick = () => {
            window.open(buttonLink, '_blank');
        }

        return (
            <div>
                <div className="flex justify-center w-full">
                    <div
                        className={`${styleConstants.boxStyle} !${styleConstants.ccriBoxBackgroundColor} max-w-4xl flex flex-col gap-8`}
                    >
                        <div className="flex flex-col lg:flex-row w-full mt-4 gap-8 items-center justify-center">
                            <Paragraph
                                content={text}
                                isWhiteText
                                isCenter
                            />
                        </div>
                        <div className="w-full flex flex-col md:flex-row justify-center items-center gap-4">
                            <Button
                                buttonText={buttonText}
                                onButtonClick={onDownloadReportButtonClick}
                                key={`box-button-${buttonText}`}
                                isWideButton
                                isExternalLink
                            />
                        </div>

                    </div>
                </div>
            </div>
        )
    }


    const renderCcriBox = () => (
        <div
            className={`${styleConstants.boxStyle} !${styleConstants.ccriBoxBackgroundColor} flex flex-col lg:flex-row gap-8 items-center justify-center`}
        >
            <div className="grow shrink">
                <Paragraph
                    content="CCRI is the leading data provider for cryptocurrency sustainability metrics. CCRI evaluates the sustainability metrics of the Solana network since 2024 and deploys industry-leading measurement methodologies for its metrics generation. In the context of the Markets in Crypto-Asset (MiCA) Regulation, sustainability data gain increasingly importance."
                    isWhiteText
                    isCenter
                    customStyle="italic"
                />
            </div>
            <div className="shrink-0 grow-0 w-full lg:w-1/4 flex items-center justify-center">
                <CcriLogo height={"h-[50px]"} />
            </div>
        </div>
    )


    const renderMethodology = () => (
        <div>
            <Enumeration
                listContent={[
                    {
                        text: ["Hardware Assessment: CCRI gathers data on the ", "."],
                        links: ["https://docs.solanalabs.com/operations/requirements"],
                        linkTexts: ["hardware requirements for the Solana network"]
                    },
                    "Hardware Measurement: CCRI maintains a state-of-the-art, temperature-controlled lab in Munich that houses a variety of hardware configurations. Depending on the hardware needs, node software is set up on the devices and monitored for their electricity usage over a full day.",
                    "Transaction Impact Analysis: CCRI monitors, alongside the power demand of an individual node, the transaction throuput of the node, building a model that allows to account for the increased electricity consumption due to larger transaction throughputs.",
                    "Network Energy Analysis: CCRI then uses an average best-guess electricity consumption and transaction throughput to calculate the total network electricity consumption by multiplying with the number of validators in the network.",
                    "Carbon Impact Estimation: By pinpointing node locations, CCRI generates a network-specific carbon intensity profile. The carbon intensity is then used to calculate the total carbon footprint of the network.",
                ]}
                isWhiteText
            />

        </div>
    )


    const renderMicaMethodology = () => (

        <div>
            <Heading
                text="MiCA Sustainability Methodologies"
                isSubheading
                hasSmallBottomPadding
            />
            <Paragraph
                content="The Markets in Crypto-Asset (MiCA) regulation requires token issuers and crypto-asset service providers (CASPs) to communicate sustainability data. In this dashboard, respective sustainability indicators are calculated and communicated."
                isWhiteText
            />
        </div>
    )


    const renderCcriPosBox = () => {

        return renderButtonBox(
            `CCRI employs a standardized methodology for Proof of Stake networks. A more detailed description on hardware selection, measurement approach, and data generation can be found in the report "Determining the electricity consumption and carbon footprint of Proof of Stake networks".`,
            "Download Report",
            "https://carbon-ratings.com/dl/whitepaper-pos-methods-2024"
        )

    }

    const renderCcriMicaBox = () => {

        return renderButtonBox(
            `CCRI calculates further sustainability metrics (in addition to electricity consumption and carbon footprint), aligned to the regulatory requirements of the MiCA regulation. For a detailed overview of methodologies, please take a look at "Methodologies to calculate the sustainability indicators required by the MiCA regulation".`,
            "Download Report",
            "https://carbon-ratings.com/dl/whitepaper-mica-methods-2024"
        )

    }

    const pageSections = [
        renderCcriPosBox(),
        renderMethodology(),
        renderCcriBox(),
        renderMicaMethodology(),
        renderCcriMicaBox()
    ]


    const renderComponent = () => {
        return (
            <div>
                <div className="flex flex-col w-full mb-8">
                    <HeroSection
                        headingText="Methodology"
                        subheadingText={"CCRI methodology to assess the Solana network."}
                    />
                </div>
                <div className="w-full flex justify-center">
                    <div className={`${styleConstants.maxWidthTextPage} flex flex-col gap-y-20`}>
                        <ScrollFadingSectionWrapper sections={pageSections} />
                    </div>
                </div>

            </div>
        )
    }

    return (
        <PageTransitionWrapper
            component={renderComponent()}
        />
    )
}