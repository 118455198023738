import { TBackendHistoric, TBackendKpi, TBackendMapData, TBackendMica, TBackendNetworkTicker, TBackendTickers } from "../../types/backend-data";
import { Action, ActionType } from "../actions/dashboardActions";


export interface IDashboardReducerState {
    tickers: TBackendTickers | undefined,
    kpiData: TBackendKpi | undefined
    mapData: TBackendMapData | undefined,
    historicData: TBackendHistoric | undefined,
    micaData: TBackendMica | undefined,
    selectedNetwork: TBackendNetworkTicker | undefined,
}
const initialState: IDashboardReducerState = {
    tickers: undefined,
    kpiData: undefined,
    mapData: undefined,
    historicData: undefined,
    micaData: undefined,
    selectedNetwork: undefined,
};


export const dashboardReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case ActionType.SET_TICKERS: {
            return {
                ...state,
                tickers: action.payload,
            };
        }
        case ActionType.SET_KPI_DATA: {
            return {
                ...state,
                kpiData: action.payload,
            };
        }
        case ActionType.SET_HISTORIC_DATA: {
            return {
                ...state,
                historicData: action.payload,
            };
        }
        case ActionType.SET_MAP_DATA: {
            return {
                ...state,
                mapData: action.payload,
            };
        }
        case ActionType.SET_MICA_DATA: {
            return {
                ...state,
                micaData: action.payload,
            }
        }
        case ActionType.SET_SELECTED_NETWORK: {
            return {
                ...state,
                selectedNetwork: action.payload,
            };
        }
        default:
            return state;
    }
};
