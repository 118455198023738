import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getUnits } from '../../pages/MainDashboard/mainDashboardHelpers';
import { TSingleLineChartData, TLineChartMetric } from '../../types/component-types';
import { formatNumber } from '../../pages/MainDashboard/mainDashboardHelpers';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';


import {
    Chart as ChartJS,
    TimeScale,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { styleConstants } from '../../style-constants';


ChartJS.register(
    TimeScale,
    LinearScale,
    LogarithmicScale,
    CategoryScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
    ChartDataLabels
);


interface ILineChartProps {
    chartMetric: TLineChartMetric,
    data: TSingleLineChartData,
}

export const LineChart: React.FC<ILineChartProps> = (props) => {

    const [chartFontSize, setChartFontSize] = useState<number>(10);
    const [key, setKey] = useState<number>(0); // State to control the key


    useEffect(() => {
        const handleResize = () => {
            setChartFontSize(window.innerWidth < 640 ? 9 : 10) //change the font size 
            setKey(prevKey => prevKey + 1);
        };
        handleResize()
        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup the event listener when the component unmounts
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getChartUnit = () => (getUnits(props.chartMetric))



    const labels = props.data.dates;
    const y1Values = props.data.y1AnnualizedValue;
    const y2Values = props.data.y2L1Share;
    const formattedMetric = props.chartMetric.charAt(0).toUpperCase() + props.chartMetric.slice(1);
    const formattedMetricY1 = `${formattedMetric} (annualized, total)`
    const formattedMetricY2 = `${formattedMetric} (annualized, L2 only)`
    const yMaxValue = Math.max(...y1Values)
    const yMaxValueWithBuffer = yMaxValue * 1.1
    const chartUnit = getChartUnit()
    const pointHoverRadius = 5
    const pointRadius = 0


    const chartData = {
        labels: labels,
        datasets: [
            {
                label: formattedMetricY1,
                data: y1Values,
                fill: false,
                borderColor: styleConstants.primaryColor,
                backgroundColor: 'transparent',
                pointBackgroundColor: "white",
                pointRadius: pointRadius,
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                pointHoverBorderColor: 'rgb(255, 255, 255)',
                pointHoverRadius: pointHoverRadius
            },
            {
                label: formattedMetricY2, // Area for y2
                data: y2Values, // Use y2Values for the area
                fill: 'origin', // Fill the area under the line
                backgroundColor: styleConstants.primaryColorDarkTransparent, // Set the color for the filled area (light purple with transparency)
                borderColor: 'transparent', // Hide the line for y2
                pointRadius: pointRadius,
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                pointHoverBorderColor: 'rgb(255, 255, 255)',
                pointHoverRadius: pointHoverRadius
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: "time",
                time: {
                    round: "day",
                    tooltipFormat: "dddd, yyyy-MM-DD",
                    maxTicksLimit: 3,
                    displayFormats: {
                        day: 'DD MM YY',
                        week: 'MMM yy',
                        month: 'MMM yy',
                        quarter: 'MMM yy',
                    },
                },
                title: {
                    display: false,
                },
                grid: {
                    // color: styleConstants.paragraphGray
                },
                ticks: {
                    color: "white",
                    maxRotation: 30, // Set the maximum label rotation to 0 degrees
                    minRotation: 30, // Set the minimum label rotation to 0 degrees,
                    font: {
                        size: chartFontSize
                    }
                },

            },
            y: {
                title: {
                    display: true,
                    text: `${formattedMetric} (${chartUnit})`,
                    color: "white",
                    font: {
                        size: chartFontSize
                    }
                },
                suggestedMax: yMaxValueWithBuffer,
                max: undefined,
                min: 0, // Set the minimum value to zero
                grid: {
                    color: styleConstants.paragraphGray
                },
                ticks: {
                    color: "white",
                    callback: (value: string | number) => {
                        return formatNumber(value as number, true)
                    },
                    font: {
                        size: chartFontSize
                    }
                }
            },
        },

        plugins: {
            tooltip: {
                intersect: false,
                position: "average",
                displayColors: false,
                cornerRadius: 10,
                titleFont: {
                    size: chartFontSize
                },
                bodyFont: {
                    size: chartFontSize
                },
                callbacks: {
                    //first two values come from the dataset
                    label: (context: any) => {
                        const label = context.dataset.label
                        const value = formatNumber(context.dataset.data[context.dataIndex]);
                        return `${label}: ${value} ${chartUnit}`;
                    },
                    //third line is appended manually
                    afterBody: (tooltipItem: any) => {
                        const label = `${formattedMetric} (day, total)`
                        const value = formatNumber(props.data.dailyValue[tooltipItem[0].dataIndex]);
                        return `${label}: ${value} ${chartUnit}`;
                    }
                }
            },
            legend: {
                display: false,
            },
            zoom: {
                pan: {
                    enabled: true,
                    scaleMode: 'x'
                },
                drag: {
                    enabled: true,
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                    animation: {
                        duration: 1000,
                        easing: 'easeOutCubic'
                    },
                    onZoomStart: (chart: any) => {
                        chart.chart.stop()
                    },
                },
                limits: {
                    x: { min: 'original', max: 'original', minRange: 7 * 24 * 60 * 60 * 1000, },
                    y: { min: 0, max: yMaxValueWithBuffer },
                },
            },
            datalabels: {
                display: false
            }
        },
        interaction: {
            mode: "index",
        },
        hover: {
            mode: 'index',
            intersect: false,

        },

    } as const


    return (
        <Line
            data={chartData}
            options={chartOptions}
            key={key} // Use the key to trigger remount
        />
    )
};

