import React from 'react';
import { TBackendKpiNetwork, TBackendNetworkTicker } from '../../types/backend-data';
import { Heading } from '../../components/Heading';
import { BarChart } from '../../components/BarChart';
import { Collapsible } from '../../components/Collapsible';
import { TBarChartData } from '../../types/component-types';
import { Paragraph } from '../../components/Paragraph';



interface IComparisonSectionProps {
    kpiData: TBackendKpiNetwork;
    network: TBackendNetworkTicker;
}

export const ComparisonSection: React.FC<IComparisonSectionProps> = (props) => {


    const comparison_data = [
        [
            "1 Google search",
            0.0003,
            "CHIPKIN",
            "http://store.chipkin.com/articles/did-you-know-it-takes-00003-kwh-per-google-search-and-more"
        ],
        [
            "1 hour of using an LED lightbulb",
            0.01,
            "CNET",
            "http://cnet.com/home/energy-and-utilities/watts-vs-lumens-how-to-choose-the-right-led-light-bulb"
        ],
        [
            "Fully charging iPhone 13 battery",
            0.01241,
            "BGR",
            "http://bgr.com/tech/iphone-13-battery-size-data-revealed-for-all-four-models"
        ],
        [
            "1 hour of working on a computer/monitor",
            0.158,
            "HES Documentation",
            "http://hes-documentation.lbl.gov/calculation-methodology/calculation-of-energy-consumption/major-appliances/miscellaneous-equipment-energy-consumption/default-energy-consumption-of-mels"
        ],
        [
            "Average US household (per hour)",
            1.223173516,
            "U.S. Energy Information Administration (EIA)",
            "http://eia.gov/tools/faqs/faq.php?id=97&t=3"
        ],
        [
            "Central air conditioning (per hour)",
            3.5,
            "Energyusecalculator.com",
            "http://energyusecalculator.com/electricity_centralac.htm"
        ],
        [
            "One gallon of gasoline",
            33.7,
            "Jdpower.com",
            "http://jdpower.com/cars/shopping-guides/what-is-kwh-per-100-miles"
        ],
        [
            "1 Bitcoin transaction (avg of 2024)",
            60.4,
            "CCRI API",
            "http://docs.api.carbon-ratings.com"
        ]
    ]



    const parseBarChartData = (): TBarChartData => {
        return comparison_data.reduce(
            (acc, [label, value, source, link]) => {
                acc.labels.push(label as string);
                acc.values.push(value as number);
                acc.sources.push(source as string);
                acc.links.push(link as string);
                return acc;
            },
            { labels: [] as string[], values: [] as number[], sources: [] as string[], links: [] as string[] }
        );
    };

    const formatDataSources = () => {
        return <div className='flex flex-col gap-3'>
            {
                comparison_data.map((elem, index) => {
                    return (
                        <div
                            key={`comparison-source-entry-${index}`}
                            className='flex flex-col'
                        >
                            <Paragraph
                                content={`${elem[0]}:`}
                                isThinText
                                isWhiteText={false}
                                isCaption
                                customStyle='!font-bold'
                            />
                            <Paragraph
                                content={
                                    {
                                        text: [""],
                                        links: [`${elem[3]}`],
                                        linkTexts: [`${elem[3]}`],
                                    }
                                }
                                isThinText
                                isWhiteText={false}
                                isCaption
                                customStyle='!font-light'
                            />
                        </div>
                    )
                })
            }
        </div>
    }


    const renderComponent = () => {

        return (
            <div
                className='flex flex-col items-center w-full'
            >
                <Heading
                    text={'Comparison of Electricity Consumption'}
                    isSubheading
                    hasMediumSmallBottomPadding
                    isMdTextCenter
                />
                <div className='flex flex-wrap justify-center w-full mx-2 px-4'>
                    <BarChart
                        data={parseBarChartData()}
                        solana_tx_elec_wh={props.kpiData.electricityTx.data / 1000}
                    />
                </div>
                <div className='mt-4 w-full'>
                    <Collapsible
                        heading='Data Sources'
                        bodyElem={formatDataSources()}
                    />
                </div>
            </div>
        )
    }

    return (
        renderComponent()
    )
};

