import { AnyAction } from "redux";
import { Dispatch } from "redux";


export enum ActionType {
    SET_BACKEND_OFFLINE = "SET_BACKEND_OFFLINE"
}


export interface SetBackendOfflineAction {
    type: ActionType.SET_BACKEND_OFFLINE
}

export type Action =
    | SetBackendOfflineAction
    | AnyAction;
    

export const setIsBackendOffline = () => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_BACKEND_OFFLINE,
        });
    };
};