import React, { useState } from 'react';
import { styleConstants } from '../../style-constants';


interface IButtonProps {
    buttonText: string;
    onButtonClick: () => void;
    isExternalLink?: boolean;
    isWideButton?: boolean,
    customStyle?: string,
    isSubmitButton?: boolean,
}

export const Button: React.FC<IButtonProps> = (props) => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const arrowTopRight = (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 17L17 7M17 7V17M17 7H7"
                stroke={"currentColor"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    )

    const styles: { button: { [key: string]: string | number } } = {
        button: {
            color: isHovered ? '#fff' : '#000',
            textAlign: 'center',
            textTransform: 'capitalize',
            cursor: 'pointer',
            background: isHovered ? styleConstants.secondaryColor : styleConstants.primaryColor,
            borderRadius: '3rem',
            padding: '10px 14px',
            fontSize: '.8rem',
            fontWeight: 500,
            textDecoration: 'none',
            transition: 'all 0.3s',
            position: 'relative',
            filter: isHovered ? 'brightness(150%) grayscale(20%)' : 'brightness(90%) grayscale(0%)',
        }
    };


    return (

        <button
            className={`flex items-center justify-center text-center" ${props.isWideButton ? "w-[210px]" : "w-32"} ${(props.customStyle ? props.customStyle : "")}`}
            style={styles.button}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={props.onButtonClick}
            type={props.isSubmitButton ? "submit" : "reset"}

        >
            <div
                className='w-full flex justify-center'>
                {props.buttonText}
            </div>
            {props.isExternalLink && (
                <div
                    className='w-5'>
                    {arrowTopRight}

                </div>
            )
            }
        </button>
    )

};
