import React, { useEffect, useRef, useState } from 'react';
import { styleConstants } from '../../style-constants';
import { Paragraph } from '../Paragraph';
import { getIconElement } from '../../icons';

interface ICollapsibleProps {
    heading: string;
    bodyElem: React.ReactNode;
    isFullWidth?: boolean;
    isInitiallyOpen?: boolean;
}

export const Collapsible: React.FC<ICollapsibleProps> = (props) => {

    const [isOpen, setIsOpen] = useState(props.isInitiallyOpen ? true : false);
    const [contentHeight, setContentHeight] = useState<string>('0px'); // Store height in state

    const contentRef = useRef<HTMLDivElement>(null);

    // Function to recalculate content height
    const updateContentHeight = () => {
        if (contentRef.current) {
            setContentHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
        }
    };

    // Function to recalculate content height on resize
    const updateResizeContentHeight = () => {
        if (contentRef.current) {
            // First, set height to 'auto' to allow recalculation on expanding window
            setContentHeight('auto');

            // Then, set height based on actual scrollHeight if open
            // setTimeout to ensure proper height calculation: This allows React to update the DOM before we set the final height based on the scrollHeight.
            setTimeout(() => {
                updateContentHeight();
            }, 0);
        }
    };

    // Update height whenever isOpen changes
    useEffect(() => {
        updateContentHeight();
    }, [isOpen]);

    // Listen to window resize to recalculate height dynamically
    useEffect(() => {
        const handleResize = () => {
            updateResizeContentHeight();
        };

        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isOpen]);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const getArrowIcon = () => {
        return isOpen ? getIconElement('arrowUp', 'text-white', undefined, undefined, undefined, undefined, true) : getIconElement('arrowDown', 'text-paragraphGray', undefined, undefined, undefined, undefined, true)
    }

    return (
        <div className='w-full flex justify-center'>

            <div
                className={`flex flex-col justify-left items-start h-full ${props.isFullWidth ? "w-full" : "w-full xs:w-3/4 md:w-1/2"} mx-8 xs:mx-0`}
            >
                <div
                    className={`group w-full flex justify-between items-center cursor-pointer border-b p-2 ${isOpen ? "border-white" : "border-paragraphGray"} hover:border-primaryColor ${styleConstants.transitionStandard}`}
                    onClick={toggleCollapse}
                >
                    <div className={`items-center flex-grow flex-shrink text-left`}>
                        <Paragraph
                            content={props.heading}
                            customStyle={`${isOpen ? "text-white !font-bold" : "text-paragraphGray"} group-hover:text-primaryColor ${styleConstants.transitionStandard}`}
                        />
                    </div>
                    <div className='w-6 text-right flex-shrink-0 flex-grow-0'>
                        {getArrowIcon()}
                    </div>
                </div>
                {/* Content that expands dynamically */}
                <div
                    ref={contentRef}
                    className={`${styleConstants.transitionSlow} overflow-hidden px-4 pt-4`}
                    style={{
                        height: contentHeight
                    }}
                >
                    {props.bodyElem}
                </div>
            </div>
        </div>
    );
};


