import React from 'react';
import { Transition } from '@headlessui/react';
import { styleConstants } from '../../style-constants';
import { useLocation } from 'react-router-dom';

interface IPageTransitionWrapperProps {
    component: JSX.Element;
    componentProps?: Record<string, any>;
}

export const PageTransitionWrapper: React.FC<IPageTransitionWrapperProps> = (props) => {

    const location = useLocation();

    return (
        <Transition
            key={location.pathname}
            as={"div"}
            show={true}
            appear={true}
            enter={`${styleConstants.transitionStandard}`}
            enterFrom="transform scale-[0.97]"
            enterTo="transform scale-100"
        >
            {props.componentProps ? (
                // Render the component JSX element with optional props
                React.cloneElement(props.component, props.componentProps)
            ) : (
                // Render the component JSX element without optional props
                props.component
            )}
        </Transition>
    );
};
