import React from 'react';
import CcriLogoWhite from "../../assets/logos/CCRI-logo.png"


interface ICcriLogoProps {
    height: string;
}

export const CcriLogo: React.FC<ICcriLogoProps> = (props) => {


    const handleLogoClick = () => {
        window.open('https://carbon-ratings.com', '_blank');
    }

    return (
        <img
            src={CcriLogoWhite}
            alt="CCRI"
            className={`${props.height} w-auto cursor-pointer`}
            onClick={handleLogoClick}
        />
    );
};
