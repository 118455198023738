import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface IRedirectComponentProps {
  to: string;
  external?: boolean;
}

export const RedirectComponent: React.FC<IRedirectComponentProps> = (props) => {

  const navigate = useNavigate();

  // Redirect to the specified link
  useEffect(() => {
    if (props.external)
      window.location.href = props.to;
    else
      navigate(props.to)
  }, [navigate, props.external, props.to]);

  return null; // No need to render anything
};

