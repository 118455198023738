import { Heading } from "../../components/Heading"
import { Paragraph } from "../../components/Paragraph"
import { styleConstants } from "../../style-constants"


interface IHeroSectionProps {
    headingText: string,
    subheadingText?: string | undefined,
}


export const HeroSection: React.FC<IHeroSectionProps> = (props) => {


    return (
        <div className="my-8 flex flex-col flex-grow items-stretch gap-8">
            <div className="flex flex-col md:flex-row w-full h-full gap-y-8 md:gap-y-0">
                <div className="flex flex-col gap-y-8 sm:gap-y-4 z-10 w-full md:min-w-[360px] md:w-3/5 lg:w-4/5">
                    <Heading
                        text={props.headingText}
                    />
                    {
                        props.subheadingText && <div>
                            <div className="w-full lg:w-2/3">
                                <div className={styleConstants.mobileClasses}>
                                    <Paragraph
                                        content={props.subheadingText}
                                        isCenter
                                    />
                                </div>
                                <div className={styleConstants.desktopClasses}>
                                    <Paragraph
                                        content={props.subheadingText}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}