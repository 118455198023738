import React from 'react';
import { MapChart } from '../../components/MapChart';
import { TBackendMapDataEntry } from '../../types/backend-data';
import { OutdatedAlert } from '../../components/OutdatedAlert';
import { Heading } from '../../components/Heading';


interface IMapSectionProps {
    networkMapDataEntry: TBackendMapDataEntry
}

export const MapSection: React.FC<IMapSectionProps> = (props) => {

    return (
        <div
            className='flex flex-col items-center w-full'
        >
            <Heading
                text={'Node locations'}
                isSubheading
                hasMediumSmallBottomPadding
                isMdTextCenter
            />
            <div className='flex flex-col items-center w-full gap-y-2'>
                <MapChart
                    networkMapData={props.networkMapDataEntry.data}
                />
                <div className='self-center sm:self-end'>
                    <OutdatedAlert timestamp={props.networkMapDataEntry.age} />
                </div>
            </div>
        </div>
    )

};

