import { backendGetHistoricData, backendGetKpiData, backendGetMapData, backendGetMicaData, backendGetTickers } from "../../services/backend-service"
import { setHistoricData, setKpiData, setMapData, setMicaData, setTickers } from "../actions/dashboardActions"
import { AppDispatch, store } from "../store"
import { handleBackendRequestError } from "./sessionReducerHelpers";
import { IDashboardReducerState } from "./dashboardReducer";


const isPropUnavailable = (storeProp: keyof IDashboardReducerState,) => {

    const dashboardState = store.getState().dashboard

    if (!dashboardState[storeProp]) {
        return true
    } else {
        return false
    }
}

export const loadUnavailableDashboardData = async (dispatch: AppDispatch) => {

    try {

        if (isPropUnavailable("tickers")) {
            const tickersData = await backendGetTickers();
            dispatch(setTickers(tickersData));
        }
        if (isPropUnavailable("kpiData")) {
            const kpiData = await backendGetKpiData();
            dispatch(setKpiData(kpiData));
        }
        if (isPropUnavailable("mapData")) {
            const mapData = await backendGetMapData();
            dispatch(setMapData(mapData));
        }
        if (isPropUnavailable("historicData")) {
            const historicData = await backendGetHistoricData();
            dispatch(setHistoricData(historicData));
        }

    }
    catch (error) {
        console.error('Error:', error);
        handleBackendRequestError(dispatch)
    }
}


export const loadUnavailableMicaData = async (dispatch: AppDispatch) => {

    try {
        if (isPropUnavailable("tickers")) {
            const tickersData = await backendGetTickers();
            dispatch(setTickers(tickersData));
        }
        if (isPropUnavailable("micaData")) {
            const micaData = await backendGetMicaData();
            dispatch(setMicaData(micaData));
        }
        if (isPropUnavailable("kpiData")) {
            const kpiData = await backendGetKpiData();
            dispatch(setKpiData(kpiData));
        }
    }
    catch (error) {
        console.error('Error:', error);
        handleBackendRequestError(dispatch)
    }
}


export const isAllDashboardDataLoaded = (): boolean => {

    if (
        isPropUnavailable("tickers")
        || isPropUnavailable("kpiData")
        || isPropUnavailable("mapData")
        || isPropUnavailable("historicData")
    ) {
        return false;
    } else {
        return true
    }

}


export const isAllMicaDataLoaded = (): boolean => {

    if (
        isPropUnavailable("tickers")
        || isPropUnavailable("micaData")
        || isPropUnavailable("kpiData")
    ) {
        return false;
    } else {
        return true
    }

}