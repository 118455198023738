import { AnyAction } from "redux";
import { Dispatch } from "redux";
import { TBackendHistoric, TBackendKpi, TBackendMapData, TBackendMica, TBackendNetworkTicker, TBackendTickers } from "../../types/backend-data";


export enum ActionType {
    SET_TICKERS = "SET_TICKERS",
    SET_KPI_DATA = "SET_KPI_DATA",
    SET_HISTORIC_DATA = "SET_HISTORIC_DATA",
    SET_MAP_DATA = "SET_MAP_DATA",
    SET_MICA_DATA = "SET_MICA_DATA",
    SET_SELECTED_NETWORK = "SET_SELECTED_NETWORK",
}

export interface SetTickersAction {
    type: ActionType.SET_TICKERS;
    payload: TBackendTickers;
}

export interface SetKpiDataAction {
    type: ActionType.SET_KPI_DATA;
    payload: TBackendKpi;
}

export interface SetHistoricDataAction {
    type: ActionType.SET_HISTORIC_DATA,
    payload: TBackendHistoric
}

export interface SetMapDataAction {
    type: ActionType.SET_MAP_DATA,
    payload: TBackendMapData
}

export interface SetMicaDataAction {
    type: ActionType.SET_MICA_DATA,
    payload: TBackendMica
}

export interface SetSelectedNetworkAction {
    type: ActionType.SET_SELECTED_NETWORK,
    payload: TBackendNetworkTicker
}

export type Action =
    | SetTickersAction
    | SetKpiDataAction
    | SetHistoricDataAction
    | SetMapDataAction
    | SetMicaDataAction
    | SetSelectedNetworkAction
    | AnyAction;


export const setTickers = (newTickers: TBackendTickers) => {
    return (dispatch: Dispatch<SetTickersAction>) => {
        dispatch({
            type: ActionType.SET_TICKERS,
            payload: newTickers
        });
    };
};

export const setKpiData = (newKpiData: TBackendKpi) => {
    return (dispatch: Dispatch<SetKpiDataAction>) => {
        dispatch({
            type: ActionType.SET_KPI_DATA,
            payload: newKpiData
        });
    };
};

export const setHistoricData = (newMapData: TBackendHistoric) => {
    return (dispatch: Dispatch<SetHistoricDataAction>) => {
        dispatch({
            type: ActionType.SET_HISTORIC_DATA,
            payload: newMapData
        });
    };
};

export const setMapData = (newMapData: TBackendMapData) => {
    return (dispatch: Dispatch<SetMapDataAction>) => {
        dispatch({
            type: ActionType.SET_MAP_DATA,
            payload: newMapData
        });
    };
};

export const setMicaData = (newMicaData: TBackendMica) => {
    return (dispatch: Dispatch<SetMicaDataAction>) => {
        dispatch({
            type: ActionType.SET_MICA_DATA,
            payload: newMicaData
        });
    };
};

export const setSelectedNetwork = (newSelectedNetwork: TBackendNetworkTicker) => {
    return (dispatch: Dispatch<SetSelectedNetworkAction>) => {
        dispatch({
            type: ActionType.SET_SELECTED_NETWORK,
            payload: newSelectedNetwork
        });
    };
};

